import React from "react"
import dateFormat from 'dateformat';
import {
  Container,
  Row,
  Col,
} from "react-bootstrap"
import Slider from "react-slick"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"


// Images
import ContactCard from "../ContactCard/ContactCard"
import "./FilterBlock.scss"

import {OtherInsights} from "../../queries/common_use_query"
import { removeDataAttributes } from "../../comQueryStructure";

const FilterBlock = props => {

  const {loading, error, data} = OtherInsights(props.insights_id);
  const otherInsightData = removeDataAttributes(data?.insights)
  const slicedInsightData = otherInsightData?.filter(data =>{
    return(
      data.Title !== props.article_data.Title
    )
  }).slice(0,5)
  
  const { pathname } = useLocation()
  var siteUrl = process.env.GATSBY_SITE_URL;
  var blogurl = siteUrl + pathname

  const blogSchemaImg = props?.article_data?.Banner_Image?.url ? props.article_data.Banner_Image.url : (props?.article_data?.Tile_Image?.url ? props?.article_data?.Tile_Image?.url : "");


  const settings = {
    dots: false,
    speed: 800,
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: true,
    arrows: false,
    mobileFirst: true,
    autoplay:true,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      }
    ],
  }
  var otherInsightsList = [];
  
  slicedInsightData?.map((insight,i) =>{
    //console.log("insight",insight)
        otherInsightsList.push({
          "@type": "Article",
          "author": {
            "@type": "Person",
            "name": "Ramy Wali",
                    "url": "https://www.thepearlgates.com/about/our-people/ramy-wali/"
             },
          "datePublished": `${insight.Date}T10:00:00+03:00`, 
          "citation": `${insight.Title} | The Pearl Gates`,
          "headline": `${insight.Title}`,
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": `${siteUrl}/about/case-studies-and-insights/${insight.URL}/`
            },
          "image":{
             "@type":"ImageObject",
             "url": `${insight.Tile_Image.url}`
            }
        })
      })
  
  return (
    <div className="filter-block">
      {otherInsightsList.length > 0 &&
      <Helmet>
          <script type="application/ld+json">{`{
              "@context": "http://schema.org",
              "@type": "Article",
              "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": "${blogurl}"
              },
              "headline": "${props.article_data.Title}",
              "image":{
                "@type":"ImageObject",
                "url":"${blogSchemaImg}"
              },
              "datePublished": "${props.article_data.Date}T10:00:00+03:00",
              "dateModified": "${props.article_data.Date}T10:00:00+03:00",
              "author": {
                "@type": "Person",
                "name":  "Ramy Wali",
                "url": "https://www.thepearlgates.com/about/our-people/ramy-wali/",
                "sameAs": "https://www.linkedin.com/in/ramy-wali-235b13b7/",
                "knowsAbout":["Qatar real estate market","Qatar property management","Dubai real estate market","Dubai property management","Qatar residential mortgages","Dubai residential mortgages","Qatar new projects","Dubai off plan projects","properties for sale in Dubai","properties for sale in Qatar"],
                "jobTitle": "CEO",
                "image":{
                      "@type":"ImageObject",
                      "@id": "https://www.thepearlgates.com/about/our-people/ramy-wali/#author-image",
                      "url":"https://ggfx-pearlgates2.s3.eu-west-2.amazonaws.com/i.prod/Ramy_Wali_b71f295080.jpg",
                      "caption": "Ramy Wali" 
                  },
                "worksFor": 
                  { 
                      "@type": "OrganizationRole", 
                      "@id": "https://www.thepearlgates.com/about/our-people/ramy-wali/#organization", 
                      "name": "The Pearl Gates", 
                      "url": "https://www.thepearlgates.com/" 
                  }
              },
              "publisher": {
                "@type": "Organization",
                "name": "The Pearl Gates",
                "logo": {
                    "@type": "ImageObject",
                    "url": "https://ggfx-pearlgates2.s3.eu-west-2.amazonaws.com/i.prod/pb_logo_2_043eb3ad46.png"
                  }
              },
              "description": "${props.article_data.Meta_Description}",
              "citation" : ${JSON.stringify(
                otherInsightsList,
                null,
                2
              )}
            
}`}</script>
 </Helmet>}
      <Container>
        <Row>
          <Col lg="12">
            <div className="filter-block-title">
               <div className="animated">
                <h2>Other insights that may interest you</h2>
               </div>
            </div>


            <div className="filter-block-list text-center text-xl-left">
              <Slider {...settings}>
                {
                  otherInsightData && otherInsightData.length > 0 && otherInsightData.map((item, index) => {
                    var date_of_item =item.Date && dateFormat(item.Date, "dd mmmm yyyy");
                    var split_tags = item.Tag ? item.Tag.split(', ') : '';
                    return(
                       <div className="animated">
                        <ContactCard
                          cardImg={item.Tile_Image}
                          cardTitle={item.Title}
                          date={date_of_item}
                          cardTag={item.Tag ? split_tags[0] : item.Category.replace('_', ' ')}
                          link_url={item.URL}
                          imagename={"insight.Tile_Image.tile_image"}
                          article_id={item.id} 
                          imagetransforms={item.ggfx_results}
                          ggfxNew={true}  
                          externalLink={item.External_Link}
                        />
                       </div>
                    )
                  })
                }
              </Slider>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default FilterBlock
