import React from "react";
import { Link } from "@StarberryUtils";
import {Container, Row, Col} from 'react-bootstrap';
import  "./AuthorBlock.scss";
import { ImageModule } from "../../modules/Image_Module";

const AuthorBlock = (props) => {
   return (
    <div className="author-block-wrap">
        <Container>
            <Row className="author-info">
                <Col sm={5} md={4} lg={3} className="author-img">
                    <figure>
                    <Link to={`/about/our-people/${props?.author?.URL}`}><ImageModule ImageSrc={props?.author?.Tile_Image} imagename={"people.Image.tile_image"} article_id={props?.author?.id} imagetransforms={props?.author?.ggfx_results} ggfxNew={true}/></Link>                         
                    </figure>    
                    <Link to={`/about/our-people/${props?.author?.URL}`} className="btn borderd-button"><span>Read Full Bio</span></Link>
                </Col>
                <Col sm={7} md={8} lg={9} className="author-bio">
                    <h3><Link to={`/about/our-people/${props?.author?.URL}`}>{props?.author?.Name}</Link></h3>
                    <h4>{props?.author?.Designation}</h4>
                    <p>{props?.author?.Highlight_Text.replace(/\.([^.]*)$/, "...$1")}</p>
                </Col>
            </Row>
        </Container>
    </div>

   )
};
export default AuthorBlock;